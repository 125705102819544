/**=====================
    57. breadcrumb CSS Start
==========================**/
.breadcrumb {
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  border-radius: 0.25rem;
}
.breadcrumb-colored {
  li {
    a {
      color: $breadcrumb-ancher-color;
    }
    &.active {
      color: $breadcrumb-ancher-color;
      opacity: 0.7;
    }
  }
  .breadcrumb-item {
    &:before {
      color: $breadcrumb-ancher-color;
    }
  }
  .breadcrumb-item.txt-dark {
    &:before {
      color: $breadcrumb-ancher-dark-color;
    }
  }
}
.breadcrumb-item {
  &.active {
    text-transform: capitalize;
  }
}
/**=====================
    57. breadcrumb CSS ends
==========================**/