/* ---------------------
 *** Vendors Scss ***
-----------------------*/



@import "vendors/chartist";
@import "vendors/dropzone";
@import "vendors/scrollable";
@import "vendors/sticky";
@import "vendors/todo";

/* Ngx-owl-carousel-o */
// @import '../../../node_modules/ngx-owl-carousel-o/lib/styles/scss/owl.carousel.scss';
// @import '../../../node_modules/ngx-owl-carousel-o/lib/styles/scss/owl.theme.default.scss';
// @import '~ngx-owl-carousel-o/lib/styles/scss/owl.carousel';
// @import '~ngx-owl-carousel-o/lib/styles/scss/owl.theme.default';


/* Toastr */
@import "~ngx-toastr/toastr.css";

// Ngx-Tabset
@import "~ngx-tabset/ngx-tabset";

/* Dragula */
@import "~dragula/dist/dragula.css";

/* Dropzone */
@import "~dropzone/dist/min/dropzone.min.css";

/* Leaflet Map */
@import "~leaflet/dist/leaflet.css";


/* Scrollbar */
@import "~perfect-scrollbar/css/perfect-scrollbar.css";

/* Emoji */
@import "~@ctrl/ngx-emoji-mart/picker";
// overlay
@import '@angular/cdk/overlay-prebuilt.css';
@import "../../../node_modules/angular-archwizard/styles/archwizard.scss";
@import "~@ng-select/ng-select/themes/default.theme.css";

/* ---------------------
	*** Theme Scss ***
-----------------------*/
@import "~animate.css/animate.min.css";
@import "~bootstrap/scss/bootstrap";
@import "style";
@import "responsive";
