/**=====================
    24. According CSS start
==========================**/
.accordion {
  & > .card {
    box-shadow: none;
    border: $card-border-color !important;
    margin-bottom: 10px !important;
    .card-header {
      margin: -1px;
      padding: 20px;
      .btn {
        padding: 0;
      }
      .btn-link {
        color: $theme-body-font-color;
        font-weight: 500;
        &:hover,
        &:focus {
          text-decoration: none;
        }
      }
    }
    &:last-child {
      margin-bottom: 0 !important;
    }
  }
}
.accordian-page, .faq-page{
  .accordion-item{
    border-radius: 6px;
    border: 1px solid $horizontal-border-color;
    &:not(:last-of-type){
      border-bottom: 0;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }
    &:not(:first-of-type){
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
    &:last-of-type{
      .accordion-button{
        &:focus{
          box-shadow: none;
        }
        &.accordion-button{
            border-radius: 6px;
          &:not(.collapsed){
            border-radius: 6px 6px 0 0;
            box-shadow: inset 0 calc(var(--bs-accordion-border-width) * -1) 0 unset;
          }
        }
      }
    }
    &:last-child{
      margin-bottom: 0;
    }
  }
  .accordion-header{
    background-color: $white;
    border-radius: 6px;
    .accordion-button{
      background-color: $white !important;
      font-size: 14px;
      border-radius: 6px 6px 0 0;
      padding: 12px 20px;
      color: $theme-body-font-color;
      font-weight: 500;
      border-bottom: 1px solid $horizontal-border-color;
      &:focus{
        box-shadow: none;
      }
      &.collapsed{
        border-bottom: transparent;
      }
      &:not(.collapsed){
        box-shadow: inset 0 calc(var(--bs-accordion-border-width) * -1) 0 unset;
      }
      app-feather-icons{
        svg{
          width: 20px;
          height: 20px;
          margin-right: 10px;
          vertical-align: middle;
        }
      }
      &:after{
        background-image: unset;
      }
    }
  }
}
.default-according {
  .accordion-header {
    svg {
      margin-right: 10px;
    }
    .accordion-button {
      color: $theme-body-font-color;
      &[aria-expanded="true"] {
        background-color: rgba(115, 102, 255, 0.15);
      }
    }
  }
  .accordion-button:not(.collapsed)::after {
    display: none;
  }
  .card {
    margin-bottom: 0;
    box-shadow: none;
    & + .card {
      margin-top: $according-card-top-margin;
    }
    .btn-link {
      font-weight: $according-btn-weight;
      color: $theme-body-font-color;
      text-decoration: none;
    }
    .btn-link {
      &:focus,
      &:hover {
        text-decoration: none;
      }
    }
    .card-header {
      padding: $according-card-header-padding;
      border: $card-border-color;
      i {
        position: absolute;
        left: 18px;
        font-size: 20px;
        top: 20px;
      }
      h5 {
        margin-top: 2px;
      }
    }
    .card-body {
      border: $card-border-color;
      border-top: none;
      padding: 25px 20px;
    }
  }
}
.default-according.style-1 {
  button {
    width: 100%;
    text-align: left;
    &:before {
      right: 20px;
      position: absolute;
      transition: 0.4s;
    }
    &[aria-expanded="true"] {
      &:before {
        content: $according-open-icon;
        font-family: $according-card-header-icon;
      }
    }
    &[aria-expanded="false"] {
      &:before {
        content: $according-close-icon;
        font-family: $according-card-header-icon;
      }
    }
  }
}
/**=====================
     24. According CSS Ends
==========================**/